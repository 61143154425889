<template>
  <v-col cols="12" class="h-100">
    <v-card class="mb-5">
      <v-card-title class="flex justify-content-between">
        <div>
          Correo electrónico:
          <span style="font-weight: 400; font-size: initial;">
            &nbsp; {{ account.inb_user }}
          </span>
        </div>

        <div>
          <v-btn class="blue darken-2 white--text" @click="get_emails">
            Cuentas
            <v-icon dark> mdi-chevron-down </v-icon>
          </v-btn>
        </div>

      </v-card-title>
    </v-card>

    <v-card>
      <div class="d-flex justify-center">
        <v-pagination v-model="form.pagination.id" :length="form.pagination.count" :total-visible="7"
          :ellipsis="'text'"></v-pagination>
      </div>

      <div class="d-flex">
        <v-tabs v-model="form.tab" grow>
          <v-tab v-for="item in items" :key="item.tag" :value="item">
            <v-icon class="mr-2">{{ item.icon }}</v-icon>
            &nbsp; {{ item.text }}
          </v-tab>
        </v-tabs>
      </div>

      <v-list style="padding-top: 0 !important">
        <div v-for="item in messages" :key="item.seq">
          <div class="d-flex align-center py-0">
            <v-list-item class="pl-2 d-flex justify-content-around" :style="style_row(item)">
              <div style="flex: 1" @click="modal_info_open(item)">
                {{ text_capitalize(get_name(item.envelope.from[0])) }}
              </div>
              <div style="flex: 3" @click="modal_info_open(item)">
                {{
                  text_capitalize(
                    item.envelope.subject ? item.envelope.subject : "Sin asunto"
                  )
                }}
              </div>
              <div style="flex: 1" @click="modal_info_open(item)">
                {{ item.envelope.date | formatDate }}
              </div>
              <div>
                <button @click="openConfirm(item)" v-if="view_button_radicar(item)" title="Radicar mensaje en SIGED">
                  <v-icon class="mr-2">mdi-stamper </v-icon>
                </button>

                <button v-if="item.radicado" title="Este correo ya fue radicado" @click="get_nro_rad(item)">
                  <v-icon class="mr-2">mdi-check-bold</v-icon>
                </button>
              </div>
            </v-list-item>
          </div>

          <v-divider class="my-0" :key="`divider-${item}`"></v-divider>
        </div>

        <div class="d-flex justify-center">
          <v-pagination v-model="form.pagination.id" :length="form.pagination.count" :total-visible="7"
            :ellipsis="'text'"></v-pagination>
        </div>
      </v-list>
    </v-card>
    <ModalForm :visible="loader.estado" @close="loader.estado = false" :message="loader.message"></ModalForm>
    <ModalInfo :status="modalInfo.status" :item="modalInfo.data" :flag="form.flag" @close="modalInfo.status = false"
      @seen="seen" :account="account"></ModalInfo>

    <ModalAccounts :status="modalAccounts.status" :data="modalAccounts.data" :account="account"
      @close="modalAccounts.status = false" @callback="setAccount">
    </ModalAccounts>

    <v-dialog v-model="modalConfirm.estado" scrollable width="auto">
      <v-card>
        <v-card-title>¿Desea radicar este mensaje en SIGED?</v-card-title>
        <v-divider class="my-1"></v-divider>
        <v-card-actions class="w-100 d-flex justify-content-end">
          <v-btn class="green darken-1 white--text" @click="radicar">
            Aceptar
          </v-btn>
          <v-btn class="red darken-1 white--text" @click="modalConfirm.estado = false">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modalDataRad.estado" scrollable width="auto">
      <v-card>
        <span class="m-2">Número de radicado: <b>{{ modalDataRad.rad }}</b></span>
        <v-card-actions class="w-100 d-flex justify-content-end px-2">
          <v-btn class="green darken-2 white--text" @click="modalDataRad.estado = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import { formatDate, formatDateUtc } from './../../plugins/filters';

import ModalForm from "../modal/ModalForm.vue";
import ModalInfo from "./modal.vue";
import ModalAccounts from "./modalAccounts.vue";

export default {
  components: {
    ModalForm,
    ModalInfo,
    ModalAccounts
  },
  data() {
    return {
      account: {
        _id: null,
        inb_user: null
      },
      userData: {},
      messages: [],
      loader: {
        estado: false,
        message: "",
      },
      modalInfo: {
        status: false,
        data: {},
      },
      modalAccounts: {
        status: false,
        data: [],
      },
      form: {
        flag: "inbox",
        tab: 0,
        pagination: {
          id: 1,
          total: 10,
          count: 10,
        },
      },
      modalConfirm: {
        estado: false,
        item: {},
      },
      modalDataRad: {
        estado: false,
        rad: null,
      },
      items: [
        {
          text: "Bandeja de entrada",
          value: "",
          icon: "mdi-inbox",
          tag: "Inbox",
          flag: "Inbox"
        },
        /*
        {
          text: "Enviados",
          value: "",
          icon: "mdi-send",
          tag: "Enviados",
          flag: "Sent"
        },
        */
      ],
      email: ''
    };
  },
  filters: {
    formatDate, formatDateUtc
  },
  mounted() {
    this.userData = this.$store.state.userdata;
    this.get_emails();
  },

  methods: {
    async get_emails() {
      try {
        this.loader.estado = true;
        this.loader.message =
          "Por favor espere mientras se consultan los datos..";

        let response = await this.axios('/imap/messages/emails', {
          params: {
            user_id: this.userData?.id || "",
            cmp_id: this.userData?.companydata?._id || "",
          }
        })

        this.loader.estado = false;
        this.modalAccounts.data = response.data.data
        if (this.modalAccounts?.data?.length == 1) {
          this.setAccount(this.modalAccounts.data[0]._id);
        } else {
          this.modalAccounts.status = true
        }
      } catch (error) {
        console.log("Error get emails: ", error)
        this.loader.estado = false;
      }
    },
    async get_boxes() {
      try {
        this.loader.estado = true;
        this.loader.message =
          "Por favor espere mientras se consultan los datos..";

        this.messages = [];

        /*
        let response = await this.axios('/imap/messages/boxes', {
          params: { email_id: this.account?._id || "" }
        })

        let data = response.data.data
        this.email = response.data.email

        for (const item of this.items) {
          let tab = data.find(e => { if (e.name == item.tag || e.name == item.flag) return e })
          item.value = tab?.key || ""
        }

        this.form.flag = this.form.flag || this.items[0].value
        */

        this.form.flag = 'inbox'

        this.get_messages()
      } catch (error) {
        console.log("Error: ", error)
        this.loader.estado = false;
      }
    },
    get_messages({ page = 1 } = {}) {
      this.loader.estado = true;
      this.loader.message =
        "Por favor espere mientras se consultan los datos..";

      this.messages = []

      this.axios
        .get("/imap/messages", {
          params: {
            take: parseInt(page * 50),
            skip: parseInt(page * 50) - 50,
            flag: this.form.flag || "inbox",
            cmp_id: this.userData?.companydata?._id || "",
            email_id: this.account?._id || ""
          },
        })
        .then((response) => {
          this.messages = response.data?.data || [];
          let { count } = response.data || {};

          this.form.pagination.total = count?.total;
          this.form.pagination.count = Math.ceil(count?.total / 50);

          this.loader.estado = false;
        })
        .catch((error) => {
          this.loader.estado = false;
          console.log("Error consultando informacion");
        });
    },
    openConfirm(item) {
      this.modalConfirm = {
        estado: true,
        item,
      };
    },
    radicar() {
      this.modalConfirm.estado = false;
      let item = this.modalConfirm.item;

      this.loader.estado = true;
      this.loader.message =
        "Por favor espere mientras se radica el documento..";

      this.axios
        .post("/imap/messages/radicar", {
          flag: this.form.flag || "inbox",
          uid: item.uid,
          email_id: this.account?._id || "",
          cmp_id: this.userData?.companydata?._id || "",
        })
        .then((res) => {
          let radicado = this.messages.find((e) => e.uid == item.uid);
          if (radicado) {
            radicado.radicado = res.data.data;
          }
          this.loader.estado = false;
        })
        .catch((err) => {
          console.log("Error: ", err);
          this.loader.estado = false;
        });
    },
    async get_nro_rad(item) {
      try {
        this.loader.estado = true;
        this.loader.message =
          "Por favor espere mientras se radica el documento..";

        let response = await this.axios.get(`/imap/messages/dcmnt/${item.radicado._id}`)

        this.loader.estado = false
        this.modalDataRad.rad = response.data.data.nro_rad
        this.modalDataRad.estado = true
      } catch (error) {
        console.log("Error", error)
        this.loader.estado = false;
      }
    },
    seen(item) {
      let message = this.messages.find((e) => e.uid == item.uid);
      if (message) {
        let seen = message.flags.find((e) => e == "\\Seen");
        if (!seen) message.flags.push("\\Seen");
      }
    },

    modal_info_open(item) {
      this.modalInfo.status = true;
      this.modalInfo.data = structuredClone(item);
    },

    setAccount(id) {
      let find = this.modalAccounts.data.find(e => e._id == id)
      this.account = structuredClone(find || {})
      this.modalAccounts.status = false
      this.get_boxes()
    },

    get_name(data = {}) {
      return data.name || data.host;
    },

    text_capitalize(text) {
      text = String(text).toLowerCase() || "";

      return String(text).charAt(0).toUpperCase() + String(text).slice(1) || "";
    },

    format_date(date) {
      return String(date).replace("T", ", ").split(".")[0] || "";
    },
    style_row(data) {
      let style = { cursor: "pointer" };

      if (data.radicado) {
        style = {
          ...style,
          background: "rgb(25 117 215 / 26%)",
        };
      }

      if (!data.flags.find((e) => e == "\\Seen")) {
        style = {
          ...style,
          "font-weight": "500",
        };
      }

      return style;
    },
    view_button_radicar(item) {
      let tag = this.items[this.form.tab].tag

      if (tag == "Enviados") {
        return false
      }

      return !item.radicado
    }
  },
  watch: {
    "form.pagination.id": function (val) {
      this.get_messages({ page: val });
    },
    "form.tab": function (val) {
      this.form.flag = this.items[val].value
      this.get_messages()
    }
  },
};
</script>

<style>
.v-input--selection-controls__input {
  margin: 0 !important;
}

.mdi-check-bold::before {
  color: #0eb20e;
}

div.v-tab {
  margin: 0 !important;
  padding: 0 !important;
}
</style>